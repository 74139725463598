import React, {ReactElement} from 'react';
import AdminPageWrapper from '../../../components/helpers/wrappers/AdminPageWrapper';
import {hasLink} from '../../../utils/HateoasFunctions';
import {InternalLink} from '../../../components/helpers/text/InternalLink';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {customerQueries} from '../../../api/Customers';

function CustomerOverview(): ReactElement {
    const rootLinks = fetchRootLinks();
    const { data: customerOverview, isLoading: isLoadingCustomers} = useQuery(customerQueries.findAll(rootLinks, {sort: 'userData.email'}));

    const customers = customerOverview?._embedded.customers || [];

    // TODO: table loader
    if(isLoadingCustomers) {
        return (
            <h3>Loading...</h3>
        );
    }


    return (
        <AdminPageWrapper title='Klanten'>
            <table className='border border-collapse'>
                <thead>
                <tr>
                    <th className='border'>Email</th>
                    <th className='border'>Voornaam</th>
                    <th className='border'>Achternaam</th>
                    <th className='border'>Details</th>
                </tr>
                </thead>
                <tbody>
                {customers.map(customer => {
                    return (
                        <tr key={customer.id}>
                            <td className='border'>{customer.email}</td>
                            <td className='border'>{customer.firstName}</td>
                            <td className='border'>{customer.lastName}</td>
                            <td className='border'>
                                {hasLink(customer, 'self') &&
                                  <InternalLink to={`${customer.id}`}>details</InternalLink>}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </AdminPageWrapper>
    );
}

export default CustomerOverview;