import React, {ReactElement} from 'react';
import {InternalLink} from '../../../components/helpers/text/InternalLink';
import AdminPageWrapper from '../../../components/helpers/wrappers/AdminPageWrapper';
import {hasLink} from '../../../utils/HateoasFunctions';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {employeeQueries} from '../../../api/Employees';

function EmployeeOverview(): ReactElement {
    const rootLinks = fetchRootLinks();
    const { data: employeeOverview, isLoading: isLoadingEmployees} = useQuery(employeeQueries.findAll(rootLinks, {sort: 'userData.email'}));

    const employees = employeeOverview?._embedded.employees || [];

    // TODO: table loader
    if(isLoadingEmployees) {
        return (
            <h3>Loading...</h3>
        );
    }

    return (
        <AdminPageWrapper title='Werknemers'>
            <table className='border border-collapse'>
                <thead>
                    <tr>
                        <th className='border'>Email</th>
                        <th className='border'>Voornaam</th>
                        <th className='border'>Achternaam</th>
                        <th className='border'>Details</th>
                    </tr>
                </thead>
                <tbody>
                {employees.map(employee => {
                    return (
                        <tr key={employee.id}>
                            <td className='border'>{employee.email}</td>
                            <td className='border'>{employee.firstName}</td>
                            <td className='border'>{employee.lastName}</td>
                            <td className='border'>
                                {hasLink(employee, 'self') && <InternalLink to={`${employee.id}`}>details</InternalLink>}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </AdminPageWrapper>
    );
}

export default EmployeeOverview;