import React, {ReactElement, ReactNode} from 'react';

interface PropType {
    children: ReactNode;
}

function SubTitle(props: PropType): ReactElement {
    return (
        <h3 className='flex items-center text-lg sm:text-xl md:text-lg lg:text-xl text-riptide-darker font-semibold'>
            {props.children}
        </h3>
    );
}

export default SubTitle;