import axios, {AxiosRequestConfig} from 'axios';
import {HasHateoasLinks} from '../types/HateoasLink';
import {CustomMap} from '../types/CustomMap';
import {hasLink, resolve} from '../utils/HateoasFunctions';
import {fetchRootLinks} from './Root';

type OptionalFollowParams = {
    urlParams?: CustomMap<string>;
    searchParams?: CustomMap<string>;
    config?: AxiosRequestConfig;
}

type OptionalFollowMutationParams = OptionalFollowParams & {
    body?: object;
}

export async function follow<T>(object: HasHateoasLinks, rel: string, optionalParams?: OptionalFollowParams): Promise<T> {
    const url = resolve(object, rel, optionalParams?.urlParams);
    appendSearchParams(url, optionalParams);
    return (await axios.get(url.href, optionalParams?.config)).data;
}

export async function followPost<T>(object: HasHateoasLinks, rel: string, optionalParams?: OptionalFollowMutationParams): Promise<T> {
    const url = resolve(object, rel, optionalParams?.urlParams);
    appendSearchParams(url, optionalParams);
    return (await axios.post(url.href, optionalParams?.body, optionalParams?.config)).data;
}

export async function followPostForm<T>(object: HasHateoasLinks, rel: string, optionalParams?: OptionalFollowMutationParams): Promise<T> {
    const url = resolve(object, rel, optionalParams?.urlParams);
    appendSearchParams(url, optionalParams);
    return (await axios.postForm(url.href, optionalParams?.body, optionalParams?.config)).data;
}

export async function followPut<T>(object: HasHateoasLinks, rel: string, optionalParams?: OptionalFollowMutationParams): Promise<T> {
    const url = resolve(object, rel, optionalParams?.urlParams);
    appendSearchParams(url, optionalParams);
    return (await axios.put(url.href, optionalParams?.body, optionalParams?.config)).data;
}

export async function followPatch<T>(object: HasHateoasLinks, rel: string, optionalParams?: OptionalFollowMutationParams): Promise<T> {
    const url = resolve(object, rel, optionalParams?.urlParams);
    appendSearchParams(url, optionalParams);
    return (await axios.patch(url.href, optionalParams?.body, optionalParams?.config)).data;
}

function appendSearchParams(url: URL, optionalParams?: OptionalFollowParams): void {
    optionalParams?.searchParams && Object.keys(optionalParams.searchParams).forEach((key: string) => {
        url.searchParams.append(key, optionalParams.searchParams![key]);
    })
}

export const isAuthenticated: () => boolean = () => hasLink(fetchRootLinks(), 'logout')
