import React, {ReactElement, ReactNode} from 'react';
import {c} from '../../../utils/CssFunctions';
import ButtonLoader from '../loaders/ButtonLoader';

// TODO: type button maken, zodat ik zowel positieve als negatieve styling kan hebben -> negatief is bijvoorbeeld annuleer, zie technician sectie
type ButtonPropsType = {
    children: ReactNode;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;

    right?: boolean;
};

function Button(props: ButtonPropsType): ReactElement {
    const disabled = props.disabled || props.loading;

    return (
        <button className={c('py-2 px-6 mx-1 text-white bg-riptide-darker border border-riptide-darker rounded transition-all duration-300', disabled ? 'cursor-not-allowed opacity-25' : 'cursor-pointer hover:border-riptide hover:bg-riptide', props.right ? 'float-right' : '')}
            disabled={disabled}
            onClick={props.onClick}>
            {props.loading ? <ButtonLoader/> : props.children}
        </button>
    );
}

export default Button;