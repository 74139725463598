import React, {ReactElement, ReactNode, useEffect} from 'react';
import MonthSelector from './MonthSelector';
import DaySelector from './DaySelector';
import SelectedDaySuggestionSelector from './SelectedDaySuggestionSelector';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
    addYearMonthWithoutSuggestions,
    completeCalendarStep,
    selectCalendarStep,
    selectDate,
    selectSlot,
    selectVisitingAddress, selectScheme, selectReadableYearMonth,
} from '../../../store/reducers/CustomerBookingWizardSlice';
import ContentWrapper from '../../helpers/wrappers/ContentWrapper';
import {useQuery} from '@tanstack/react-query';
import {Address} from '../../../model/Address';
import {fetchRootLinks} from '../../../api/Root';
import {suggestionQueries} from '../../../api/Suggestions';
import Tooltip from '../../helpers/wrappers/Tooltip';

function Calendar(): ReactElement {
    const dispatch = useAppDispatch();

    const calendarStepVisibilityState = useAppSelector(selectCalendarStep);
    const selectedDate = useAppSelector(selectDate);
    const selectedSlot = useAppSelector(selectSlot);
    const nextButtonDisabled = !selectedDate || !selectedSlot;

    const selectedYearMonth = useAppSelector(selectReadableYearMonth);
    const visitingAddress: Address = useAppSelector(selectVisitingAddress)!;
    const scheme = useAppSelector(selectScheme);

    const calculateCustomerSuggestionsBody: () => object = () => {
        return {
            address: visitingAddress,
            schemeId: scheme!.id,
            source: 'CUSTOMER',
        };
    };

    const rootLinks = fetchRootLinks();
    const { data: suggestions, isLoading: isLoadingSuggestions, isSuccess: suggestionsSuccessfullyFetched, dataUpdatedAt: fetchTimer} = useQuery(suggestionQueries.customerSuggestions(rootLinks, visitingAddress, selectedYearMonth, scheme?.id || '', calculateCustomerSuggestionsBody));

    useEffect(() => {
        if(suggestionsSuccessfullyFetched && !Object.keys(suggestions).length) {
            dispatch(addYearMonthWithoutSuggestions(selectedYearMonth));
        }
    }, [suggestionsSuccessfullyFetched, suggestions, fetchTimer]);

    const tooltip: () => ReactNode = () => {
        return (
            <>
                <p>Als je jouw gewenste tijdstip niet kunt vinden in onze agenda, aarzel dan niet om ons telefonisch te contacteren. Wij helpen je graag verder om een geschikt moment te vinden dat past in jouw schema.</p>
                <br />
                <p>Bel ons op 015 79 24 24 en wij zorgen ervoor dat jouw afspraak geregeld wordt.</p>
            </>
        );
    }

    return (
        <ContentWrapper title="Datum & Tijd" contentLoading={isLoadingSuggestions}
                        isActive={calendarStepVisibilityState.isActive} nextButtonDisabled={nextButtonDisabled}
                        onNextButtonClick={() => dispatch(completeCalendarStep())}>
            <MonthSelector/>
            <DaySelector suggestions={suggestions || {}}/>
            <SelectedDaySuggestionSelector suggestions={suggestions || {}}/>
            <Tooltip hoverText='Afspraak niet gevonden?' content={tooltip()} textColor='riptide-darker'/>
        </ContentWrapper>
    );
}

export default Calendar;