import React, {ChangeEvent, ReactElement} from 'react';
import {ExtraService} from '../../../model/ExtraService';
import CheckBox from '../../helpers/forms/CheckBox';

type ExtraServicesComponentPropsType = {
    extraService: ExtraService;
    checked: boolean;
    addExtraService: () => void;
    removeExtraService: () => void;
}

function ExtraServiceComponent(props: ExtraServicesComponentPropsType): ReactElement {
    const handleCheckChange: (event: ChangeEvent<HTMLInputElement>) => void = (event: ChangeEvent<HTMLInputElement>) => {
        const checked: boolean = event.target.checked;
        if (checked) {
            props.addExtraService();
        } else {
            props.removeExtraService();
        }
    }

    return (
        <CheckBox id={props.extraService.id} value={props.extraService.id} title={props.extraService.name} description={props.extraService.price.toString()} checked={props.checked} onChange={handleCheckChange} />
    );
}

export default ExtraServiceComponent;