import React, {ReactElement} from 'react';
import SubTitle from '../../helpers/text/Subtitle';
import ExtraServiceComponent from '../../booking/extra-services/ExtraServiceComponent';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
    addExtraService,
    removeExtraService,
    selectExtraServices
} from '../../../store/reducers/SecretariatBookingSlice';
import StepCompleted from '../StepCompleted';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {extraServiceQueries} from '../../../api/ExtraServices';

function ExtraServicesSelector(): ReactElement {
    const dispatch = useAppDispatch();

    const rootLinks = fetchRootLinks();
    const { data: extraServices} = useQuery(extraServiceQueries.findAll(rootLinks));
    const selectedExtraServices = useAppSelector(selectExtraServices);

    // TODO: wrapper maken voor secretariat sectie met loader
    return (
        <div>
            <SubTitle>Extra diensten <StepCompleted complete={true} /></SubTitle>
            <div className='w-full flex flex-col items-center justify-center'>
                {extraServices?.map(extraService => <ExtraServiceComponent key={extraService.id} extraService={extraService} checked={selectedExtraServices.includes(extraService)} addExtraService={() => dispatch(addExtraService(extraService))} removeExtraService={() => dispatch(removeExtraService(extraService))} />)}
            </div>
        </div>
    );
}

export default ExtraServicesSelector;