import React, {ReactElement} from 'react';
import ContentWrapper from '../../helpers/wrappers/ContentWrapper';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
    completeOverviewStep,
    selectOverviewStep,
    selectExtraServices,
    selectScheme, selectSlot, selectVisitingAddress, selectReadableDate,
} from '../../../store/reducers/CustomerBookingWizardSlice';
import ElementHeader from './ElementHeader';
import ElementComponent from './ElementComponent';
import {followPost} from '../../../api/HelperFunctions';
import {useMutation} from '@tanstack/react-query';
import {Customer} from '../../../model/User';
import {Scheme} from '../../../model/Scheme';
import {fetchRootLinks} from '../../../api/Root';

function Overview(): ReactElement {
    const dispatch = useAppDispatch();

    const selectedDate = useAppSelector(selectReadableDate);
    const selectedSlot = useAppSelector(selectSlot);
    const selectedScheme: Scheme = useAppSelector(selectScheme) || {id: '', title: '', description: '', price: 0, duration: {hours: 0, minutes: 0}};
    const visitingAddress = useAppSelector(selectVisitingAddress);
    const selectedExtraServices = useAppSelector(selectExtraServices);

    const createCustomerBookingBody: () => object = () => {
        return {
            date: selectedDate,
            startTime: selectedSlot!.start,
            schemeId: selectedScheme!.id,
            visitingAddress: visitingAddress,
            extraServiceIds: selectedExtraServices.map(extraService => extraService.id),
            source: 'CUSTOMER',
        }
    };

    const rootLinks = fetchRootLinks();
    const createCustomerBookingMutation = useMutation({
        mutationFn: () => followPost<Customer>(rootLinks, 'create-customer-booking', {body: createCustomerBookingBody()}),
        onSuccess: () => {
            dispatch(completeOverviewStep());
        },
    });

    const overviewStepVisibilityState = useAppSelector(selectOverviewStep);

    return (
        <ContentWrapper title='Overzicht' isActive={overviewStepVisibilityState.isActive}
                        buttonLoading={createCustomerBookingMutation.isPending}
                        onNextButtonClick={() => createCustomerBookingMutation.mutate()}>
            <div className="space-y-2">
                <ElementHeader title="Afspraak"/>
                <ElementComponent description={selectedScheme.description} price={'€' + selectedScheme.price} />

                {selectedExtraServices.length > 0 && <br />}
                {selectedExtraServices.length > 0 && <ElementHeader title="Extra's"/>}
                {selectedExtraServices.map(extraService => <ElementComponent key={extraService.id} description={extraService.name} price={'€' + extraService.price} />)}

                <br />
                <ElementHeader title="Prijs"/>
                <ElementComponent description="Totale prijs (inclusief BTW)" price={'€' + (selectedScheme.price + selectedExtraServices.map(extraService => extraService.price).reduce((a, b) => a + b, 0))} />
            </div>
        </ContentWrapper>
    );
}

export default Overview