import React, {ChangeEvent, ReactElement} from 'react';

interface Props {
    onLabel: string;
    offLabel?: string;
    value: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function Toggle(props: Props): ReactElement {
    return (
        <div className="inline-flex items-center space-x-3">
            {props.offLabel && <span className="text-sm font-medium">{props.offLabel}</span>}
            <label className="cursor-pointer">
                <input type="checkbox" className="sr-only peer" checked={props.value} onChange={props.onChange}/>
                <div
                    className="relative w-11 h-6 bg-gray-lighter rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-riptide-darker"></div>
            </label>
            <span className="text-sm font-medium">{props.onLabel}</span>
        </div>
    );
}

export default Toggle;