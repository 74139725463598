import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Customer, Technician} from '../../model/User';
import {RootState} from '../Store';
import {Scheme} from '../../model/Scheme';
import {ExtraService} from '../../model/ExtraService';
import {Slot} from '../../model/Slot';
import moment from 'moment/moment';
import {YEAR_MONTH_FORMAT} from '../../utils/constants/Formatters';
import {City} from '../../model/City';
import {Address} from '../../model/Address';
import {XOR} from '../../types/Xor';

export enum BookingMode {
    EFFICIENT,
    INEFFICIENT
}

export type EfficiencyTypeWithTechnicianIds = {
    bookingMode: BookingMode
} & XOR<Efficient, Inefficient>;

type Efficient = {
    technicians: Array<Technician>;
};

type Inefficient = {
    technician?: Technician
};

type SecretariatBookingReducerStateType = {
    email?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    postalCode?: string;
    city?: City
    street?: string;
    houseNumber?: string;
    box?: string;
    scheme?: Scheme;
    extraServices: Array<ExtraService>;
    efficiencyTypeWithTechnicianIds: EfficiencyTypeWithTechnicianIds;
    maxTravelTime: number;
    yearMonth: string;
    date?: string;
    slot?: Slot;
};

const initialState: SecretariatBookingReducerStateType = {
    extraServices: [],
    efficiencyTypeWithTechnicianIds: {
        bookingMode: BookingMode.EFFICIENT,
        technicians: []
    },
    maxTravelTime: 30,
    yearMonth: moment().format(YEAR_MONTH_FORMAT),
};

export const secretariatBookingSlice = createSlice({
    name: 'secretariatBooking',
    initialState,
    reducers: {
        setCustomerData: (state, action: PayloadAction<Customer>) => {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.phoneNumber = action.payload.phoneNumber;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setFirstName: (state, action: PayloadAction<string>) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action: PayloadAction<string>) => {
            state.lastName = action.payload;
        },
        setPhoneNumber: (state, action: PayloadAction<string>) => {
            state.phoneNumber = action.payload;
        },
        setPostalCode: (state, action: PayloadAction<string>) => {
            state.postalCode = action.payload;
            state.city = undefined;
        },
        setCity: (state, action: PayloadAction<City | undefined>) => {
            state.city = action.payload;

            state.date = undefined;
            state.slot = undefined;
        },
        setStreet: (state, action: PayloadAction<string>) => {
            state.street = action.payload;
        },
        setHouseNumber: (state, action: PayloadAction<string>) => {
            state.houseNumber = action.payload;
        },
        setBox: (state, action: PayloadAction<string>) => {
            state.box = action.payload;
        },
        setScheme: (state, action: PayloadAction<Scheme>) => {
            state.scheme = action.payload;

            if(state.efficiencyTypeWithTechnicianIds.bookingMode === BookingMode.EFFICIENT) {
                state.efficiencyTypeWithTechnicianIds.technicians = [];
            }else if(state.efficiencyTypeWithTechnicianIds.bookingMode === BookingMode.INEFFICIENT){
                state.efficiencyTypeWithTechnicianIds.technician = undefined;
            }

            state.date = undefined;
            state.slot = undefined;
        },
        addExtraService: (state, action: PayloadAction<ExtraService>) => {
            const extraService = action.payload;
            if(state.extraServices.indexOf(extraService) === -1) {
                state.extraServices = [...state.extraServices, extraService];
            }
        },
        removeExtraService: (state, action: PayloadAction<ExtraService>) => {
            const extraService = action.payload;
            const index = state.extraServices.map(extraService => extraService.id).indexOf(extraService.id);
            if(index !== -1) {
                state.extraServices = [
                    ...state.extraServices.slice(0, index),
                    ...state.extraServices.slice(index + 1)
                ];
            }
        },
        setBookingMode: (state, action: PayloadAction<BookingMode>) => {
            state.efficiencyTypeWithTechnicianIds.bookingMode = action.payload;

            state.efficiencyTypeWithTechnicianIds.technicians = [];
            state.efficiencyTypeWithTechnicianIds.technician = undefined;
        },
        setTechnician: (state, action: PayloadAction<Technician>) => {
            state.efficiencyTypeWithTechnicianIds.technician = action.payload;

            state.date = undefined;
            state.slot = undefined;
        },
        addTechnician: (state, action: PayloadAction<Technician>) => {
            const technician = action.payload;
            if(state.efficiencyTypeWithTechnicianIds.technicians!.indexOf(technician) === -1) {
                state.efficiencyTypeWithTechnicianIds.technicians = [...state.efficiencyTypeWithTechnicianIds.technicians!, technician];
            }

            state.date = undefined;
            state.slot = undefined;
        },
        removeTechnician: (state, action: PayloadAction<Technician>) => {
            const technician = action.payload;
            const index = state.efficiencyTypeWithTechnicianIds.technicians!.map(technician => technician.id).indexOf(technician.id);
            if(index !== -1) {
                state.efficiencyTypeWithTechnicianIds.technicians! = [
                    ...state.efficiencyTypeWithTechnicianIds.technicians!.slice(0, index),
                    ...state.efficiencyTypeWithTechnicianIds.technicians!.slice(index + 1)
                ];
            }

            state.date = undefined;
            state.slot = undefined;
        },
        setMaxTravelTime: (state, action: PayloadAction<number>) => {
            state.maxTravelTime = action.payload;

            state.date = undefined;
            state.slot = undefined;
        },
        previousMonth: (state) => {
            state.date = undefined;
            state.slot = undefined;
            state.yearMonth = moment(state.yearMonth, YEAR_MONTH_FORMAT).subtract(1, 'month').format(YEAR_MONTH_FORMAT);
        },
        nextMonth: (state) => {
            state.date = undefined;
            state.slot = undefined;
            state.yearMonth = moment(state.yearMonth, YEAR_MONTH_FORMAT).add(1, 'month').format(YEAR_MONTH_FORMAT);
        },
        setDate: (state, action: PayloadAction<string>) => {
            state.date = action.payload;
            state.slot = undefined;
        },
        setSlot: (state, action: PayloadAction<Slot>) => {
            state.slot = action.payload;
        },
        resetState: () => {
            return {...initialState};
        }
    },
});

export const {setCustomerData, setEmail, setFirstName, setLastName, setPhoneNumber, setPostalCode, setCity, setHouseNumber, setStreet, setBox, setScheme, addExtraService, removeExtraService, setBookingMode, setTechnician, addTechnician, removeTechnician, setMaxTravelTime, previousMonth, nextMonth, setDate, setSlot, resetState } = secretariatBookingSlice.actions;

export const selectEmail = (state: RootState) => state.secretariatBooking.email;
export const selectFirstName = (state: RootState) => state.secretariatBooking.firstName;
export const selectLastName = (state: RootState) => state.secretariatBooking.lastName;
export const selectPhoneNumber = (state: RootState) => state.secretariatBooking.phoneNumber;
export const selectUserInfoEntered = (state: RootState) => !!(state.secretariatBooking.firstName && state.secretariatBooking.lastName && state.secretariatBooking.phoneNumber);
export const selectCustomer: (state: RootState) => Customer = (state: RootState) => {
    return {
        email: state.secretariatBooking.email,
        firstName: state.secretariatBooking.firstName,
        lastName: state.secretariatBooking.lastName,
        phoneNumber: state.secretariatBooking.phoneNumber
    }
};

export const selectPostalCode = (state: RootState) => state.secretariatBooking.postalCode;
export const selectCity = (state: RootState) => state.secretariatBooking.city;
export const selectStreet = (state: RootState) => state.secretariatBooking.street;
export const selectHouseNumber = (state: RootState) => state.secretariatBooking.houseNumber;
export const selectBox = (state: RootState) => state.secretariatBooking.box;
export const selectVisitingAddress: (state: RootState) => Address = (state: RootState) => {
    return {
        city: state.secretariatBooking.city!,
        street: state.secretariatBooking.street!,
        houseNumber: state.secretariatBooking.houseNumber!,
        box: state.secretariatBooking.box,
    }
};
export const selectAddressInfoEntered = (state: RootState) => !!(state.secretariatBooking.postalCode && state.secretariatBooking.city && state.secretariatBooking.street && state.secretariatBooking.houseNumber);

export const selectScheme = (state: RootState) => state.secretariatBooking.scheme;
export const selectBookingMode = (state: RootState) => state.secretariatBooking.efficiencyTypeWithTechnicianIds.bookingMode;
export const selectEfficientTechnicians = (state: RootState) => state.secretariatBooking.efficiencyTypeWithTechnicianIds.technicians;
export const selectInefficientTechnician = (state: RootState) => state.secretariatBooking.efficiencyTypeWithTechnicianIds.technician;
export const selectExtraServices = (state: RootState) => state.secretariatBooking.extraServices;
export const selectMaxTravelTime = (state: RootState) => state.secretariatBooking.maxTravelTime;

export const selectYearMonth = (state: RootState) => moment(state.secretariatBooking.yearMonth, YEAR_MONTH_FORMAT).locale('nl-be');
export const selectReadableYearMonth = (state: RootState) => state.secretariatBooking.yearMonth;

export const selectDate = (state: RootState) => state.secretariatBooking.date ? moment(state.secretariatBooking.date) : undefined;
export const selectReadableDate = (state: RootState) => state.secretariatBooking.date ? state.secretariatBooking.date : undefined;

export const selectSlot = (state: RootState) => state.secretariatBooking.slot;
export const selectDateAndSlotInfoEntered = (state: RootState) => !!(state.secretariatBooking.date && state.secretariatBooking.slot);

export const selectEfficiencyTypeWithTechnicianIds = (state: RootState) => state.secretariatBooking.efficiencyTypeWithTechnicianIds;

export default secretariatBookingSlice.reducer;