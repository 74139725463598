import {HasHateoasLinks} from '../types/HateoasLink';
import {follow} from './HelperFunctions';
import {hasLink} from '../utils/HateoasFunctions';
import {Overview, PagedOverview} from '../types/Overview';
import {Technician, User} from '../model/User';
import {UseQueryOptions} from '@tanstack/react-query/src/types';
import axios from 'axios';

export const technicianQueries = {
    findAllUnpagedByCompetenceId: (rootLinks: HasHateoasLinks, competenceId: string): UseQueryOptions<Overview<Technician>> => ({
        queryKey: ['technicians', competenceId, 'unpaged'],
        queryFn: () => follow<Overview<Technician>>(rootLinks, 'technicians', {urlParams: {competenceId, unpaged: 'true'}}),
        enabled: !!competenceId && hasLink(rootLinks, 'technicians'),
    }),
    // TODO: give pageable object
    findAll: (rootLinks: HasHateoasLinks, pageable: {sort: string}): UseQueryOptions<PagedOverview<Technician>> => ({
        queryKey: ['technicians', pageable],
        queryFn: () => follow<PagedOverview<Technician>>(rootLinks, 'technicians', {searchParams: pageable}),
        enabled: hasLink(rootLinks, 'technicians'),
    }),
    findById: (url: string): UseQueryOptions<Technician> => ({
        queryKey: ['technicians', url],
        queryFn: () => axios.get<Technician>(url).then(response => response.data),
        retry: false,
    }),
    findFromUser: (user?: User): UseQueryOptions<Technician> => ({
        queryKey: ['technicians', user?.id],
        queryFn: () => follow<Technician>(user!, 'technician-data'),
        enabled: !!user && hasLink(user, 'technician-data'),
    }),
};