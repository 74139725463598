import React, {ReactElement} from 'react';
import {IconChevronLeft, IconChevronRight} from '@tabler/icons-react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
    nextMonth,
    previousMonth,
    selectYearMonth,
} from '../../../store/reducers/SecretariatBookingSlice';
import moment from 'moment/moment';
import {c} from '../../../utils/CssFunctions';

function MonthSelector(): ReactElement {
    const dispatch = useAppDispatch();
    const selectedYearMonth = useAppSelector(selectYearMonth);

    const currentYearMonth = moment();
    const twoMonthsInFuture = moment(currentYearMonth).add(1, 'month');

    const canGoPreviousMonth: () => boolean = () => {
        return selectedYearMonth.isAfter(currentYearMonth);
    }

    const canGoNextMonth: () => boolean = () => {
        return selectedYearMonth.isBefore(twoMonthsInFuture);
    }

    return (
        <div className='px-10 pb-6 w-full flex items-center justify-between'>
            <span className='focus:outline-none text-base font-bold text-black capitalize'>{selectedYearMonth.format('MMMM')} {selectedYearMonth.year()}</span>
            <div className='flex items-center'>
                <button aria-label='Vorige maand' className={c('text-black hover:opacity-20', canGoPreviousMonth() ? '' : 'invisible')} disabled={!canGoPreviousMonth()} onClick={() => dispatch(previousMonth())}>
                    <IconChevronLeft />
                </button>
                <button aria-label='Volgende maand' className={c('text-black hover:opacity-20', canGoNextMonth() ? '' : 'invisible')} disabled={!canGoNextMonth()} onClick={() => dispatch(nextMonth())}>
                    <IconChevronRight />
                </button>
            </div>
        </div>
    );
}

export default MonthSelector;