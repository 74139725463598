import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
    selectDate,
    setDate,
    selectYearMonth
} from '../../../store/reducers/CustomerBookingWizardSlice';
import moment, {Moment} from 'moment';
import {c} from '../../../utils/CssFunctions';
import {CustomMap} from '../../../types/CustomMap';
import {Slot} from '../../../model/Slot';
import {DATE_FORMAT} from '../../../utils/constants/Formatters';

interface PropType {
    suggestions: CustomMap<Array<Slot>>
}

function DaySelector(props: PropType): ReactElement {
    const dispatch = useAppDispatch();

    const selectedYearMonth: Moment = useAppSelector(selectYearMonth);
    const selectedDate: Moment | undefined = useAppSelector(selectDate);

    const getDaysPerWeek: () => Array<Array<number | null>> = () => {
        const firstDayOfMonth: Moment = moment(selectedYearMonth).startOf('month');
        const lastDayOfMonth: Moment = moment(selectedYearMonth).endOf('month');

        const result: Array<Array<number | null>> = [];
        let day = 1;
        let week: Array<number | null> = [];
        if (firstDayOfMonth.isoWeekday() !== 1) {
            for (let i = 1; i < firstDayOfMonth.isoWeekday(); i++) {
                week.push(null);
            }
            const amountOfDaysInFirstWeek = 7 - firstDayOfMonth.isoWeekday() + 1;
            for (let i = 0; i < amountOfDaysInFirstWeek; i++) {
                week.push(day);
                day++;
            }
            result.push(week);
            week = [];
        }
        while (day <= lastDayOfMonth.date()) {
            for (let i = 1; i <= 7; i++) {
                if (day <= lastDayOfMonth.date()) {
                    week.push(day);
                    day++;
                } else {
                    week.push(null);
                }
            }
            result.push(week);
            week = [];
        }

        return result;
    }


    const renderDay: (day: number | null, key: number) => ReactElement = (day: number | null, key: number) => {
        const isSelectedDay = day === null ? false : selectedDate?.isSame(moment(selectedYearMonth).set('date', day));
        const extraDivStyle = day === null ? '' : (!isAvailableDay(day) ? 'cursor-not-allowed' : 'cursor-pointer border ' + (isSelectedDay ? 'bg-riptide-darker border-riptide-darker' : 'bg-bayleaf/10 border-bayleaf/60'));
        const extraPStyle = day === null ? '' : (!isAvailableDay(day) ? 'text-black opacity-20' : (isSelectedDay ? 'text-white' : 'text-black'));

        return (
            <td key={key} className='px-0.5 py-0.5 md:px-1 md:py-1'>
                <div
                    className={c('w-7 h-5 sm:w-9 sm:h-7 md:w-11 md:h-8 xl:w-14 xl:h-10 rounded flex justify-center items-center', extraDivStyle)} {...(day && isAvailableDay(day) && {'onClick': () => dispatch(setDate(moment(selectedYearMonth).set('date', day).format(DATE_FORMAT)))})}>
                    {day !== null &&
                      <p className={c('text-base', extraPStyle)}>{day}</p>
                    }
                </div>
            </td>
        );
    }

    const isAvailableDay: (day: number) => boolean = (day: number) => {
        const current = moment(selectedYearMonth).set('date', day);
        return props.suggestions[current.format(DATE_FORMAT)] !== undefined;
    }

    const daysPerWeek = getDaysPerWeek();

    return (
        <div className='flex items-center justify-between py-2 sm:py-4 md:py-6 w-56 sm:w-72 md:w-96 xl:w-[28rem]'>
            <table className='w-full'>
                <thead>
                <tr>
                    {moment.weekdaysMin(true).map(title => {
                        return (
                            <th key={title}>
                                <div className='w-full flex justify-center'>
                                    <p className='text-base font-medium text-center text-black capitalize'>{title}</p>
                                </div>
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {daysPerWeek.map((week, weekIndex) => {
                    return (
                        <tr key={weekIndex}>
                            {week.map((day, dayIndex) => renderDay(day, weekIndex * 7 + dayIndex))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default DaySelector;