import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import ExtraServiceComponent from './ExtraServiceComponent';
import ContentWrapper from '../../helpers/wrappers/ContentWrapper';
import {completeExtraServicesStep, selectExtraServicesStep, addExtraService, removeExtraService, selectExtraServices} from '../../../store/reducers/CustomerBookingWizardSlice';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {extraServiceQueries} from '../../../api/ExtraServices';

function ExtraServicesSelector(): ReactElement | null {
    const dispatch = useAppDispatch();

    const rootLinks = fetchRootLinks();
    const { data: extraServices, isLoading: isLoadingExtraServices} = useQuery(extraServiceQueries.findAll(rootLinks));

    const extraServicesSelectorStepVisibilityState = useAppSelector(selectExtraServicesStep);
    const selectedExtraServices = useAppSelector(selectExtraServices);

    if(!extraServicesSelectorStepVisibilityState.isActive) {
        return null;
    }

    return (
        <ContentWrapper title="Extra diensten" contentLoading={isLoadingExtraServices} isActive={extraServicesSelectorStepVisibilityState.isActive} nextButtonDisabled={false} onNextButtonClick={() => dispatch(completeExtraServicesStep())}>
            {extraServices?.map(extraService => <ExtraServiceComponent key={extraService.id} extraService={extraService} checked={selectedExtraServices.includes(extraService)} removeExtraService={() => dispatch(removeExtraService(extraService))} addExtraService={() => dispatch(addExtraService(extraService))} />)}
        </ContentWrapper>
    );
}

export default ExtraServicesSelector;