import React, {ReactElement} from 'react';
import { NavItemDataType } from '../NavItemDataType';
import {hasLink} from '../../../utils/HateoasFunctions';
import {HasHateoasLinks} from '../../../types/HateoasLink';
import {visibleItems} from '../HeaderHelper';
import NavItem from './NavItem';
import {fetchRootLinks} from '../../../api/Root';

export const sidebarData: (links: HasHateoasLinks) => Array<NavItemDataType> = (links: HasHateoasLinks) => [
    {
        title: 'Werknemers',
        show: hasLink(links, 'employees'),
        url: 'employees',
    },
    {
        title: 'Techniekers',
        show: hasLink(links, 'technicians'),
        url: 'technicians',
    },
    {
        title: 'Klanten',
        show: hasLink(links, 'customers'),
        url: 'customers',
    },
    {
        title: 'Gebruikers',
        show: hasLink(links, 'users'),
        url: 'users',
    },
];


function Sidebar(): ReactElement {
    const rootLinks = fetchRootLinks();

    return (
        <header>
            <ul className='list-none flex flex-col shadow-md'>
                {visibleItems(sidebarData(rootLinks)).map((item, index) => <NavItem item={item} key={index} />)}
            </ul>
        </header>
    )
}

export default Sidebar;