import React, {ChangeEvent, ReactElement} from 'react';
import SubTitle from '../../helpers/text/Subtitle';
import {Technician} from '../../../model/User';
import CheckBox from '../../helpers/forms/CheckBox';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
    addTechnician,
    BookingMode,
    removeTechnician,
    selectBookingMode,
    selectEfficientTechnicians,
    selectInefficientTechnician, selectScheme,
    setTechnician
} from '../../../store/reducers/SecretariatBookingSlice';
import StepCompleted from '../StepCompleted';
import Radio from '../../helpers/forms/Radio';
import {useQuery} from '@tanstack/react-query';
import {fetchRootLinks} from '../../../api/Root';
import {technicianQueries} from '../../../api/Technicians';

function TechnicianSelector(): ReactElement | null {
    const dispatch = useAppDispatch();

    const selectedScheme = useAppSelector(selectScheme);

    const rootLinks = fetchRootLinks();
    const { data: techniciansOverview} = useQuery(technicianQueries.findAllUnpagedByCompetenceId(rootLinks, selectedScheme?.id || ''));

    const technicians = techniciansOverview?._embedded.technicians || [];
    const selectedEfficientTechnicians = useAppSelector(selectEfficientTechnicians);
    const selectedInefficientTechnician = useAppSelector(selectInefficientTechnician);
    const selectedBookingMode = useAppSelector(selectBookingMode);

    // TODO: wrapper maken voor secretariat sectie met loader
    if (selectedBookingMode === BookingMode.EFFICIENT) {
        return (
            <div>
                <SubTitle>Techniekers <StepCompleted complete={selectedEfficientTechnicians!.length > 0}/></SubTitle>
                <div className='w-full flex flex-col items-center justify-center'>
                    {technicians.map(technician => <TechnicianCheckboxComponent key={technician.id}
                                                                              technician={technician}
                                                                              checked={selectedEfficientTechnicians!.includes(technician)}
                                                                              addTechnician={() => dispatch(addTechnician(technician))}
                                                                              removeTechnician={() => dispatch(removeTechnician(technician))}/>)}
                </div>
            </div>
        );
    } else if (selectedBookingMode === BookingMode.INEFFICIENT) {
        return (
            <div>
                <SubTitle>Technieker <StepCompleted complete={!!selectedInefficientTechnician}/></SubTitle>
                <div className='w-full flex flex-col items-center justify-center'>
                    {technicians.map(technician => <TechnicianRadioComponent key={technician.id} technician={technician}
                                                                           checked={selectedInefficientTechnician === technician}
                                                                           setTechnician={() => dispatch(setTechnician(technician))}/>)}
                </div>
            </div>
        );
    }
    // Should never happen
    return null;
}

type TechnicianCheckboxComponentPropsType = {
    technician: Technician;
    checked: boolean;
    addTechnician: () => void;
    removeTechnician: () => void;
}

function TechnicianCheckboxComponent(props: TechnicianCheckboxComponentPropsType): ReactElement {
    const handleCheckChange: (event: ChangeEvent<HTMLInputElement>) => void = (event: ChangeEvent<HTMLInputElement>) => {
        const checked: boolean = event.target.checked;
        if (checked) {
            props.addTechnician();
        } else {
            props.removeTechnician();
        }
    }

    return (
        <CheckBox id={props.technician.id!} value={props.technician.id!}
                  title={props.technician.firstName + ' ' + props.technician.lastName}
                  description={props.technician.email!}
                  checked={props.checked} onChange={handleCheckChange}/>
    );
}

type TechnicianRadioComponentPropsType = {
    technician: Technician;
    checked: boolean;
    setTechnician: () => void;
}

function TechnicianRadioComponent(props: TechnicianRadioComponentPropsType): ReactElement {
    return (
        <Radio id={props.technician.id!} value={props.technician.id!}
               title={props.technician.firstName + ' ' + props.technician.lastName}
               description={props.technician.email!}
               checked={props.checked} onChange={props.setTechnician}/>
    );
}

export default TechnicianSelector;