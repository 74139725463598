import React, {ReactElement, ReactNode} from 'react';
import {Link} from 'react-router-dom';

interface PropType {
    children: ReactNode;
    to: string;
}

export function InternalLink(props: PropType): ReactElement {
    return (
        <Link to={props.to} className='underline text-riptide-darker'>
            {props.children}
        </Link>
    );
}