import React, {ReactElement, ReactNode} from 'react';
import {Title} from '../text/Title';

type ContentWrapperPropsType = {
    title: string;
    children: ReactNode;
}

function AdminPageWrapper(props: ContentWrapperPropsType): ReactElement {
    return (
        <div className='flex flex-col w-full items-center p-5 md:p-8 space-y-5'>
            <Title>{props.title}</Title>
            {props.children}
        </div>
    );
}

export default AdminPageWrapper;