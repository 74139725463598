import React, {MutableRefObject, ReactElement} from 'react';
import {c} from '../../../utils/CssFunctions';
import Tooltip from '../wrappers/Tooltip';

interface Props {
    label: string;
    name: string;
    value: string;
    onChange?: (event: any) => void;
    width?: string;
    required?: boolean;
    disabled?: boolean;
    underline?: boolean;
    tooltip?: string;
    reference?: MutableRefObject<HTMLInputElement | null>;
}

function TextField(props: Props): ReactElement {
    return (
        <div className={c('inline-block pb-2 pt-4 px-1', props.width || 'w-full', props.disabled ? 'opacity-40' : '')}>
            <div
                className={c('relative transition-all duration-300 border-gray-lighter focus-within:border-riptide', props.underline ? 'border-b-2' : 'border-2')}>
                <input
                    className="relative block w-full appearance-none focus:outline-none bg-transparent text-riptide-darker focus:text-riptide py-1 pl-1"
                    ref={props.reference}
                    type='text' name={props.name} id={props.name} disabled={props.disabled} value={props.value}
                    onChange={props.onChange}
                    placeholder=" "/>
                {props.tooltip ? <div className={c('absolute top-2 -left-4')}>
                    <Tooltip
                        disabled={props.disabled}
                        content={props.tooltip!}/>
                </div> : null}
                <label
                    className={c('absolute top-2 left-1 origin-left transition-all duration-300 text-gray cursor-text',
                        'sibling-placeholder-not-shown:font-semibold sibling-placeholder-not-shown:text-riptide-darker sibling-placeholder-not-shown:transform sibling-placeholder-not-shown:scale-75 sibling-placeholder-not-shown:-translate-x-1 sibling-placeholder-not-shown:cursor-default',
                        'focused-sibling:font-semibold focused-sibling:text-riptide focused-sibling:transform focused-sibling:scale-75 focused-sibling:-translate-x-1 focused-sibling:cursor-default',
                        props.underline ? 'focused-sibling:-translate-y-6 sibling-placeholder-not-shown:-translate-y-6' : 'focused-sibling:-translate-y-8 sibling-placeholder-not-shown:-translate-y-8')}
                    htmlFor={props.name}>{props.label}{props.required && '*'}</label>
            </div>
        </div>
    );
}

export default TextField;