import {HasHateoasLinks} from '../types/HateoasLink';
import {follow} from './HelperFunctions';
import {hasLink} from '../utils/HateoasFunctions';
import {ExtraService} from '../model/ExtraService';
import type {UseQueryOptions} from '@tanstack/react-query/src/types';

export const extraServiceQueries = {
    findAll: (rootLinks: HasHateoasLinks): UseQueryOptions<Array<ExtraService>> => ({
        queryKey: ['extra-services'],
        queryFn: () => follow<Array<ExtraService>>(rootLinks, 'extra-services'),
        enabled: hasLink(rootLinks, 'extra-services'),
    }),
};