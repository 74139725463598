import React, {ReactElement} from 'react';
import {InternalLink} from '../../../components/helpers/text/InternalLink';
import AdminPageWrapper from '../../../components/helpers/wrappers/AdminPageWrapper';
import {hasLink} from '../../../utils/HateoasFunctions';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {userQueries} from '../../../api/Users';

function UserOverview(): ReactElement {
    const rootLinks = fetchRootLinks();
    const { data: userOverview, isLoading: isLoadingUsers} = useQuery(userQueries.findAll(rootLinks, {sort: 'email'}));

    const users = userOverview?._embedded.users || [];

    // TODO: table loader
    if(isLoadingUsers) {
        return (
            <h3>Loading...</h3>
        );
    }

    return (
        <AdminPageWrapper title='Gebruikers'>
            <table className='border border-collapse'>
                <thead>
                    <tr>
                        <th className='border'>Email</th>
                        <th className='border'>Voornaam</th>
                        <th className='border'>Achternaam</th>
                        <th className='border'>Details</th>
                    </tr>
                </thead>
                <tbody>
                {users.map(user => {
                    return (
                        <tr key={user.id}>
                            <td className='border'>{user.email}</td>
                            <td className='border'>{user.firstName}</td>
                            <td className='border'>{user.lastName}</td>
                            <td className='border'>
                                {hasLink(user, 'self') && <InternalLink to={`${user.id}`}>details</InternalLink>}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </AdminPageWrapper>
    );
}

export default UserOverview;