import React, {Dispatch, ReactElement, useState} from 'react';
import {c} from '../../../utils/CssFunctions';
import NavbarDesktop from './desktop/Navbar';
import NavbarMobile from './mobile/Navbar';
import {visibleItems} from '../HeaderHelper';
import {NavItemDataType} from '../NavItemDataType';
import {hasLink} from '../../../utils/HateoasFunctions';
import {followPost, isAuthenticated} from '../../../api/HelperFunctions';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {fetchRootLinks} from '../../../api/Root';
import {userQueries} from '../../../api/Users';
import {useAppDispatch} from '../../../store/hooks';
import {logout} from '../../../store/reducers/AuthenticationSlice';

function Header(): ReactElement {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    const [mobileVisible, setMobileVisible]: [boolean, Dispatch<boolean>] = useState<boolean>(false);

    const rootLinks = fetchRootLinks();
    const { data: authenticatedUser} = useQuery(userQueries.findAuthenticatedUser(rootLinks));

    const logoutMutation = useMutation({
        mutationFn: () => followPost<void>(rootLinks, 'logout'),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['rootLinks']});
            dispatch(logout);
            // TODO: hier zit iets niet juist, waardoor hij de current user opnieuw ophaald. Rond gewerkt met timeout, maar moet beter kunnen
            setTimeout(() =>
                queryClient.removeQueries({predicate: query => query.queryKey[0] !== 'rootLinks'}),
                100);
        },
    })

    const welcomeText = 'Welkom' + (authenticatedUser?.firstName ? (' ' + authenticatedUser?.firstName) : '');

    const headerData: Array<NavItemDataType> = [
        {
            title: 'Home',
            url: 'https://www.afgekeurd.be',
        },
        {
            title: 'Maak afspraak',
            url: 'booking',
        },
        {
            title: 'Secretariaat',
            show: hasLink(rootLinks, 'create-secretariat-booking'),
            url: 'secretariat',
        },
        {
            title: 'Admin paneel',
            // TODO: iemand die admin paneel hier kan zien, maar geen rechten heeft tot werknemers, krijgt de knop te zien maar die lijkt niets te doen dan, todo: tegelijke fix maken wanneer rollen veranderen naar data
            // show: sidebarData(rootLinks).some(elem => elem.show),
            show: hasLink(rootLinks, 'employees'),
            url: 'admin',
        },
        {
            title: 'Aanmelden',
            show: hasLink(rootLinks, 'login'),
            url: 'login',
        },
        {
            title: welcomeText,
            show: isAuthenticated(),
            subItems: [
                {
                    title: 'Profiel',
                    show: hasLink(rootLinks, 'profile'),
                    url: '/profile',
                },
                {
                    title: 'Afmelden',
                    show: hasLink(rootLinks, 'logout'),
                    onClick: () => logoutMutation.mutate(),
                },
            ],
        },
    ];

    return (
        <header className={c('z-50 sticky left-0 top-0 w-full shadow-md py-2.5', mobileVisible ? 'bg-darkgray sm:bg-white-darker' : 'bg-white-darker')}>
            {/* TODO insert logo ergens? Ik vermoed hier! */}
            <NavbarDesktop items={visibleItems(headerData)} />
            <NavbarMobile items={visibleItems(headerData)} visible={mobileVisible} setVisible={setMobileVisible} />
        </header>
    )
}

export default Header;