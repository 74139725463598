import React, {ReactElement} from 'react';
import {NavItemDataType} from '../NavItemDataType';
import {Link, useLocation} from 'react-router-dom';
import {c} from '../../../utils/CssFunctions';
import AnimatedUnderline from '../../helpers/wrappers/AnimatedUnderline';
import {ADMIN_PANEL_PREFIX} from '../../../utils/AdminPanelUrl';

type NavItemPropType = {
    item: NavItemDataType;
};

function NavItem(props: NavItemPropType): ReactElement {
    return (
        <li className='flex bg-white-darker cursor-pointer'>
            <NavActionItem item={props.item} />
        </li>
    );
}

function NavActionItem(props: NavItemPropType): ReactElement {
    const location = useLocation();
    const isSelected = location.pathname.slice(ADMIN_PANEL_PREFIX.length).slice('/'.length).startsWith(props.item.url || '');

    return (
        <Link to={props.item.url!}
              className={c('font-semibold py-2.5 px-5 uppercase text-sm decoration-[3px] underline-offset-4 w-full transition-all duration-300', isSelected ? 'text-riptide' : 'text-riptide-darker hover:text-riptide')}>
            <AnimatedUnderline colorStyles='riptide' alwaysVisible={isSelected}>
                {props.item.title}
            </AnimatedUnderline>
        </Link>
    )
}

export default NavItem;