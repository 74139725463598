import React, {ReactElement, ReactNode} from 'react';
import SubTitle from '../text/Subtitle';
import SectionLoader from '../loaders/SectionLoader';

type ContentWrapperPropsType = {
    title: string;
    isLoading: boolean;
    children: ReactNode;
}

function AdminSectionWrapper(props: ContentWrapperPropsType): ReactElement {
    return (
        <div className='flex flex-col w-full md:w-11/12 items-center space-y-3'>
            <SubTitle>{props.title}</SubTitle>
            <div className='w-full'>
                {
                    props.isLoading ?
                    <SectionLoader /> :
                    props.children
                }
            </div>
        </div>
    );
}

export default AdminSectionWrapper;