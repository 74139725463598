import {HasHateoasLinks} from '../types/HateoasLink';
import {follow} from './HelperFunctions';
import {hasLink} from '../utils/HateoasFunctions';
import {User} from '../model/User';
import {PagedOverview} from '../types/Overview';
import {UseQueryOptions} from '@tanstack/react-query/src/types';
import axios from 'axios';

export const userQueries = {
    findAuthenticatedUser: (rootLinks: HasHateoasLinks): UseQueryOptions<User> => ({
        queryKey: ['current-user'],
        queryFn: () => follow<User>(rootLinks, 'current-user'),
        enabled: hasLink(rootLinks, 'current-user'),
    }),
    // TODO: give pageable object
    findAll: (rootLinks: HasHateoasLinks, pageable: {sort: string}): UseQueryOptions<PagedOverview<User>> => ({
        queryKey: ['users', pageable],
        queryFn: () => follow<PagedOverview<User>>(rootLinks, 'users', {searchParams: pageable}),
        enabled: hasLink(rootLinks, 'users'),
    }),
    findById: (url: string): UseQueryOptions<User> => ({
        queryKey: ['users', url],
        queryFn: () => axios.get<User>(url).then(response => response.data),
        retry: false,
    })
};