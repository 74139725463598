import React, {MutableRefObject, ReactElement, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import TextField from '../../helpers/forms/TextField';
import {City} from '../../../model/City';
import DropDown from '../../helpers/forms/DropDown';
import SubTitle from '../../helpers/text/Subtitle';
import {
    selectAddressInfoEntered,
    selectBox,
    selectCity,
    selectHouseNumber, selectPostalCode,
    selectStreet, setBox,
    setCity, setHouseNumber, setPostalCode, setStreet
} from '../../../store/reducers/SecretariatBookingSlice';
import StepCompleted from '../StepCompleted';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {cityQueries} from '../../../api/Cities';

function VisitingAddressInfo(): ReactElement {
    const dispatch = useAppDispatch();

    const city = useAppSelector(selectCity);
    const postalCode = useAppSelector(selectPostalCode) || '';
    const street = useAppSelector(selectStreet) || '';
    const houseNumber = useAppSelector(selectHouseNumber) || '';
    const box = useAppSelector(selectBox) || '';

    const rootLinks = fetchRootLinks();
    const { data: cities, isLoading: isLoadingCities} = useQuery(cityQueries.findAll(rootLinks));

    const streetReference: MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(null);

    const findPossibleCities: () => Array<City> = () => {
        if (postalCode.length !== 4 || !cities) {
            return [];
        }
        return cities.filter(city => city.postalCode.toString() === postalCode);
    }

    const possibleCities = findPossibleCities();

    const chooseCity: (cityId: string) => void = (cityId: string) => {
        const cities = possibleCities.filter(city => city.id === cityId);
        if (cities.length === 1) {
            dispatch(setCity(cities[0]));
        } else {
            dispatch(setCity(undefined));
        }
    }

    const addressInfoEntered = useAppSelector(selectAddressInfoEntered);

    // TODO: wrapper maken voor secretariat sectie met loader
    return (
        <div>
            <SubTitle>Bezoekadres <StepCompleted complete={addressInfoEntered} /></SubTitle>
            <TextField width='w-full md:w-1/4' label="Postcode" name="postalCode"
                       value={postalCode} onChange={event => dispatch(setPostalCode(event.target.value))}
                       required/>
            <DropDown width='w-full md:w-3/4' label='Gemeente' name='city'
                      value={city?.id} selectItem={item => chooseCity(item.value)}
                      nextFocusReference={streetReference}
                      options={possibleCities.map(city => {
                          return {label: city.name, value: city.id};
                      })} disabled={!postalCode} loading={isLoadingCities} required/>
            <TextField width='w-full md:w-7/12' label="Straat" name="street"
                       reference={streetReference}
                       value={street} onChange={event => dispatch(setStreet(event.target.value))}
                       required/>
            <TextField width='w-1/2 md:w-3/12' label="Nummer" name="houseNumber"
                       value={houseNumber} onChange={event => dispatch(setHouseNumber(event.target.value))}
                       required/>
            <TextField width='w-1/2 md:w-2/12' label="Bus" name="box" value={box}
                       onChange={event => dispatch(setBox(event.target.value))}/>
        </div>
    );
}

export default VisitingAddressInfo;