import React, {ReactElement} from 'react';
import {NavItemDataType} from '../../NavItemDataType';
import NavItem from './NavItem';
import {IconMenu2, IconX} from '@tabler/icons-react';
import {Transition} from '@headlessui/react';

type NavbarPropType = {
    items: Array<NavItemDataType>;
    visible: boolean;
    setVisible: (val: boolean) => void;
}

function Navbar(props: NavbarPropType): ReactElement {

    const hideOverlayFunction: () => void = () => {
        props.setVisible(false);
    }

    return (
        <>
            <div className='sm:hidden mx-4 flex items-center justify-end'>
                <button onClick={() => props.setVisible(!props.visible)}>
                    {props.visible ? <IconX className="text-white"/> : <IconMenu2/>}
                </button>
            </div>
            <Transition
                show={props.visible}
                enter="transition ease-out duration-150"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <nav className="sm:hidden absolute w-full h-screen bg-darkgray">
                    <ul className="list-none flex flex-col">
                        {props.items.map((item, index) => <NavItem item={item} key={index} hideOverlayFunction={hideOverlayFunction} />)}
                    </ul>
                </nav>
            </Transition>
        </>
    );
}

export default Navbar;