import React, {ReactElement} from 'react';
import {NavItemDataType} from '../../NavItemDataType';
import NavItem from './NavItem';

type NavbarPropType = {
    items: Array<NavItemDataType>
}

function Navbar(props: NavbarPropType): ReactElement {
    return (
        <nav className="hidden sm:block">
            <ul className="list-none flex flex-row items-center space-x-1 mx-4 sm:mx-9 md:mx-12 lg:mx-16 justify-center">
                {props.items.map((item, index) => <NavItem item={item} key={index} />)}
            </ul>
        </nav>
    );
}

export default Navbar;