import {Outlet} from 'react-router-dom';
import React, {ReactElement} from 'react';
import Sidebar from '../../components/navigation/admin-sidebar/Sidebar';

function Layout(): ReactElement {
    return (
        <div className='flex flex-row'>
            <Sidebar/>
            <Outlet/>
        </div>
    );
}

export default Layout;