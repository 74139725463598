import React, {ReactElement, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import TextField from '../../helpers/forms/TextField';
import SubTitle from '../../helpers/text/Subtitle';
import {
    selectEmail,
    selectFirstName,
    selectLastName,
    selectPhoneNumber, selectUserInfoEntered, setCustomerData, setEmail, setFirstName, setLastName, setPhoneNumber
} from '../../../store/reducers/SecretariatBookingSlice';
import Button from '../../helpers/forms/Button';
import StepCompleted from '../StepCompleted';
import {useQuery} from '@tanstack/react-query';
import {fetchRootLinks} from '../../../api/Root';
import {customerQueries} from '../../../api/Customers';

function UserInfo(): ReactElement {
    const dispatch = useAppDispatch();
    const [shouldFetch, setShouldFetch] = React.useState(false);

    const email = useAppSelector(selectEmail) || '';
    const firstName = useAppSelector(selectFirstName) || '';
    const lastName = useAppSelector(selectLastName) || '';
    const phoneNumber = useAppSelector(selectPhoneNumber) || '';

    const rootLinks = fetchRootLinks();
    const { data: customerData, isLoading: isLoadingCustomerData, dataUpdatedAt: fetchTimer } = useQuery(customerQueries.findByEmail(rootLinks, email, shouldFetch, setShouldFetch));

    useEffect(() => {
        if(customerData) {
            dispatch(setCustomerData(customerData));
        }
    }, [customerData, fetchTimer]);

    const userInfoEntered = useAppSelector(selectUserInfoEntered);

    return (
        <div>
            <SubTitle>Klant informatie <StepCompleted complete={userInfoEntered} /></SubTitle>
            <TextField label="Email" name="email"
                       value={email} onChange={event => dispatch(setEmail(event.target.value))}
                       disabled={isLoadingCustomerData}/>
            <div className='flex justify-end'>
                <Button onClick={() => setShouldFetch(true)} loading={isLoadingCustomerData}>Klant ophalen</Button>
            </div>
            <TextField width='w-full md:w-1/2' label="Voornaam" name="firstName"
                       value={firstName} onChange={event => dispatch(setFirstName(event.target.value))}
                       required disabled={isLoadingCustomerData}/>
            <TextField width='w-full md:w-1/2' label="Achternaam" name="lastName"
                       value={lastName} onChange={event => dispatch(setLastName(event.target.value))}
                       required disabled={isLoadingCustomerData}/>
            <TextField label="Telefoonnummer" name="phoneNumber" value={phoneNumber}
                       onChange={event => dispatch(setPhoneNumber(event.target.value))}
                       required disabled={isLoadingCustomerData}/>
        </div>
    );
}

export default UserInfo;