import Header from '../../components/navigation/header/Header';
import {Outlet} from 'react-router-dom';
import React, {ReactElement} from 'react';

function Layout(): ReactElement {
    return (
        <div className='bg-white'>
            <Header/>
            <Outlet/>
        </div>
    );
}

export default Layout;