import React, {MutableRefObject, ReactElement, KeyboardEvent} from 'react';
import {c} from '../../../utils/CssFunctions';
import {IconChevronDown} from '@tabler/icons-react';

interface OptionType {
    value: string
    label: string;
}

interface Props {
    label: string;
    name: string;
    value?: string;
    selectItem: (item: OptionType) => void;
    width?: string;
    required?: boolean;
    disabled?: boolean;
    loading?: boolean;
    options: Array<OptionType>;
    underline?: boolean;
    nextFocusReference: MutableRefObject<HTMLInputElement | null>;
}

function DropDown(props: Props): ReactElement {
    const DEFAULT_NOTHING_SELECTED = {value: '', label: ''};
    const selected: OptionType = props.value ? (props.options.find(option => option.value === props.value) || DEFAULT_NOTHING_SELECTED) : DEFAULT_NOTHING_SELECTED;

    const dropdownOption: (option: OptionType) => ReactElement = (option: OptionType) => {
        function handleEnterPress(event: KeyboardEvent<HTMLDivElement>, option: OptionType) {
            if (event.key === 'Enter') {
                onSelectOption(option);
            }
        }

        const onSelectOption: (option: OptionType) => void = (option: OptionType) => {
            props.selectItem(option);
            props.nextFocusReference.current!.focus();
        }

        return (
            <div key={option.value} tabIndex={0}
                 className={c('transition-all duration-300 p-2 cursor-pointer border-b last:border-0 border-gray-lighter hover:bg-riptide hover:text-white', option.value === selected?.value ? 'bg-riptide-darker text-white-darker' : '')}
                 onClick={() => onSelectOption(option)}
                 onKeyDown={(event) => handleEnterPress(event, option)}>
                {option.label}
            </div>
        );
    };

    return (
        <div className={c('inline-block pt-2 pb-6 px-1', props.width || 'w-full', props.disabled ? 'opacity-40' : '')}>
            <div
                className={c('relative transition-all duration-300 border-gray-lighter focus-within:border-riptide', props.underline ? 'border-b-2' : 'border-2')}>
                <input
                    className={c('relative block w-full appearance-none focus:outline-none bg-transparent text-riptide-darker focus:pointer-events-none focus:text-riptide z-10 py-1 pl-1', props.disabled ? '' : 'cursor-pointer')}
                    type='text' name={props.name} id={props.name} disabled={props.disabled} readOnly
                    value={selected.label}
                    placeholder=" "/>
                <IconChevronDown size={18}
                                 className={c('absolute text-riptide-darker right-0 top-2 transition-all focused-sibling:-rotate-180')}/>
                <label
                    className={c('absolute top-2 left-1 origin-left transition-all duration-300 text-gray',
                        'sibling-placeholder-not-shown:font-semibold sibling-placeholder-not-shown:text-riptide-darker sibling-placeholder-not-shown:transform sibling-placeholder-not-shown:scale-75 sibling-placeholder-not-shown:-translate-x-1',
                        'focused-sibling:font-semibold focused-sibling:text-riptide focused-sibling:transform focused-sibling:scale-75 focused-sibling:-translate-x-1',
                        props.underline ? 'focused-sibling:-translate-y-6 sibling-placeholder-not-shown:-translate-y-6' : 'focused-sibling:-translate-y-8 sibling-placeholder-not-shown:-translate-y-8')}
                    htmlFor={props.name}>{props.label}{props.required && '*'}</label>

                {!props.disabled &&
                    <div
                        className="absolute left-0 top-9 w-full rounded-lg border-2 border-gray-lighter bg-white transition-all delay-75 opacity-0 focused-sibling:opacity-100 -z-10 focused-sibling:z-50 focus-within:opacity-100 focus-within:z-50">
                        {props.options.map(option => dropdownOption(option))}
                    </div>
                }
            </div>
        </div>
    );
}

export default DropDown;