import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {completeSchemeStep, selectSchemeStep, finalizeSchemeSelection, selectScheme, setScheme} from '../../../store/reducers/CustomerBookingWizardSlice';
import ContentWrapper from '../../helpers/wrappers/ContentWrapper';
import SchemeComponent from './SchemeComponent';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {schemeQueries} from '../../../api/Schemes';

function SchemeSelector(): ReactElement {
    const dispatch = useAppDispatch();

    const rootLinks = fetchRootLinks();
    const { data: schemes, isLoading: isLoadingSchemes} = useQuery(schemeQueries.findAll(rootLinks));

    const onNextButtonClick: () => void = () => {
        dispatch(completeSchemeStep());
        dispatch(finalizeSchemeSelection());
    }

    const schemeSelectorStepVisibilityState = useAppSelector(selectSchemeStep);
    const selectedScheme = useAppSelector(selectScheme);

    return (
        <ContentWrapper title="Selecteer dienst" isActive={schemeSelectorStepVisibilityState.isActive} contentLoading={isLoadingSchemes} nextButtonDisabled={!selectedScheme} onNextButtonClick={() => onNextButtonClick()}>
            {schemes?.map(scheme => <SchemeComponent key={scheme.id} scheme={scheme} checked={scheme === selectedScheme} onSelectScheme={() => dispatch(setScheme(scheme))} />)}
        </ContentWrapper>
    );
}

export default SchemeSelector;