import {HasHateoasLinks} from '../types/HateoasLink';
import {follow} from './HelperFunctions';
import {hasLink} from '../utils/HateoasFunctions';
import {Scheme} from '../model/Scheme';
import type {UseQueryOptions} from '@tanstack/react-query/src/types';

export const schemeQueries = {
    findAll: (rootLinks: HasHateoasLinks): UseQueryOptions<Array<Scheme>> => ({
        queryKey: ['schemes'],
        queryFn: () => follow<Array<Scheme>>(rootLinks, 'schemes'),
        enabled: hasLink(rootLinks, 'schemes'),
    }),
};