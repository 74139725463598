import React, {ReactElement} from 'react';
import AdminPageWrapper from '../../../components/helpers/wrappers/AdminPageWrapper';
import {hasLink} from '../../../utils/HateoasFunctions';
import UserSection from './sections/UserSection';
import CustomerSection from './sections/CustomerSection';
import EmployeeSection from './sections/EmployeeSection';
import TechnicianSection from './sections/TechnicianSection';
import {useQuery} from '@tanstack/react-query';
import {customerQueries} from '../../../api/Customers';
import {employeeQueries} from '../../../api/Employees';
import {technicianQueries} from '../../../api/Technicians';
import {findByIdApiUrlFromBrowserAdminPanelUrl} from '../../../utils/AdminPanelUrl';

function TechnicianDetails(): ReactElement {
    const { data: technicianDetail, isLoading: isLoadingTechnicianDetail} = useQuery(technicianQueries.findById(findByIdApiUrlFromBrowserAdminPanelUrl()));

    const { data: customerDetail, isLoading: isLoadingCustomerDetail} = useQuery(customerQueries.findFromUser(technicianDetail));
    const { data: employeeDetail, isLoading: isLoadingEmployeeDetail} = useQuery(employeeQueries.findFromUser(technicianDetail));

    return (
        <AdminPageWrapper title='Gebruikers-gegevens'>
            <UserSection isLoading={isLoadingTechnicianDetail} user={technicianDetail} />
            {
                technicianDetail && hasLink(technicianDetail, 'customer-data') &&
              <CustomerSection isLoading={isLoadingCustomerDetail} customer={customerDetail} />
            }
            {
                technicianDetail && hasLink(technicianDetail, 'employee-data') &&
              <EmployeeSection isLoading={isLoadingEmployeeDetail} employee={employeeDetail} />
            }
            <TechnicianSection isLoading={isLoadingTechnicianDetail} technician={technicianDetail} />
        </AdminPageWrapper>
    );
}

export default TechnicianDetails;