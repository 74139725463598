import {HasHateoasLinks} from '../types/HateoasLink';
import {follow} from './HelperFunctions';
import {City} from '../model/City';
import {hasLink} from '../utils/HateoasFunctions';
import type {UseQueryOptions} from '@tanstack/react-query/src/types';

export const cityQueries = {
    findAll: (rootLinks: HasHateoasLinks): UseQueryOptions<Array<City>> => ({
        queryKey: ['cities'],
        queryFn: () => follow<Array<City>>(rootLinks, 'cities'),
        enabled: hasLink(rootLinks, 'cities'),
    }),
};