import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../Store';
import {Customer} from '../../model/User';
import {Address} from '../../model/Address';
import {Scheme} from '../../model/Scheme';
import {ExtraService} from '../../model/ExtraService';
import {Slot} from '../../model/Slot';
import moment from 'moment/moment';
import {YEAR_MONTH_FORMAT} from '../../utils/constants/Formatters';

export type WizardStepType = {
    // TODO: nog niet zeker over deze types
    isActive: boolean;
    isCompleted: boolean;
    canOpen: boolean;
};

type WizardType = {
    userInfo: WizardStepType;
    visitingAddressInfo: WizardStepType;
    scheme: WizardStepType;
    extraServices: WizardStepType;
    calendar: WizardStepType;
    overview: WizardStepType;
};

// TODO: deze moet hernoemt worden naar CustomerBookingWizardReducer
type CustomerBookingWizardReducerStateType = {
    monthsWithoutSuggestion: Array<string>,
    wizard: WizardType;

    customer?: Customer;
    visitingAddress?: Address;
    scheme?: Scheme;
    extraServices: Array<ExtraService>;
    yearMonth: string;
    date?: string;
    slot?: Slot;
};

const initialState: CustomerBookingWizardReducerStateType = {
    monthsWithoutSuggestion: [],
    wizard: {
        userInfo: {
            isActive: true,
            isCompleted: false,
            canOpen: true,
        },
        visitingAddressInfo: {
            isActive: false,
            isCompleted: false,
            canOpen: false,
        },
        scheme: {
            isActive: false,
            isCompleted: false,
            canOpen: false,
        },
        extraServices: {
            isActive: false,
            isCompleted: false,
            canOpen: false,
        },
        calendar: {
            isActive: false,
            isCompleted: false,
            canOpen: false,
        },
        overview: {
            isActive: false,
            isCompleted: false,
            canOpen: false,
        },
    },

    extraServices: [],
    yearMonth: moment().format(YEAR_MONTH_FORMAT),
}

export const customerBookingWizardSlice = createSlice({
    name: 'customerBookingWizard',
    initialState,
    reducers: {
        completeCustomerInfoStep: (state) => {
            state.wizard.userInfo.isActive = false;
            state.wizard.userInfo.isCompleted = true;
            state.wizard.visitingAddressInfo.isActive = true;
        },
        completeVisitingAddressInfoStep: (state) => {
            state.wizard.visitingAddressInfo.isActive = false;
            state.wizard.visitingAddressInfo.isCompleted = true;
            state.wizard.visitingAddressInfo.canOpen = true;
            state.wizard.scheme.isActive = true;
            state.wizard.scheme.canOpen = true;
        },
        completeSchemeStep: (state) => {
            state.wizard.scheme.isActive = false;
            state.wizard.scheme.isCompleted = true;
            state.wizard.scheme.canOpen = true;
            state.wizard.extraServices.isActive = true;
            state.wizard.extraServices.canOpen = true;
        },
        completeExtraServicesStep: (state) => {
            state.wizard.extraServices.isActive = false;
            state.wizard.extraServices.isCompleted = true;
            state.wizard.extraServices.canOpen = true;
            state.wizard.calendar.isActive = true;
            state.wizard.calendar.canOpen = true;
        },
        completeCalendarStep: (state) => {
            state.wizard.calendar.isActive = false;
            state.wizard.calendar.isCompleted = true;
            state.wizard.calendar.canOpen = true;
            state.wizard.overview.isActive = true;
            state.wizard.overview.canOpen = true;
        },
        completeOverviewStep: (state) => {
            state.wizard.overview.isCompleted = true;
            // TODO: redirect hoort vanuit de component te komen en met de redirect component
            window.location.href = 'https://afgekeurd.be/afspraak-gelukt/';
        },
        addYearMonthWithoutSuggestions: (state, action: PayloadAction<string>) => {
            if(!state.monthsWithoutSuggestion.includes(action.payload)) {
                state.monthsWithoutSuggestion = [...state.monthsWithoutSuggestion, action.payload];
                if (state.monthsWithoutSuggestion.length === 3) {
                    alert('Vind je geen afspraak? Contacteer ons!');
                }
            }
        },
        setCustomerData: (state, action: PayloadAction<Customer>) => {
            state.customer = action.payload;
        },
        setFirstName: (state, action: PayloadAction<string>) => {
            state.customer = {
                ...state.customer!,
                firstName: action.payload,
            };
        },
        setLastName: (state, action: PayloadAction<string>) => {
            state.customer = {
                ...state.customer!,
                lastName: action.payload,
            };
        },
        setPhoneNumber: (state, action: PayloadAction<string>) => {
            state.customer = {
                ...state.customer!,
                phoneNumber: action.payload,
            };
        },
        setVisitingAddress: (state, action: PayloadAction<Address>) => {
            state.visitingAddress = action.payload;
        },
        setScheme: (state, action: PayloadAction<Scheme>) => {
            state.scheme = action.payload;
        },
        finalizeSchemeSelection: (state) => {
            state.date = undefined;
            state.slot = undefined;
        },
        addExtraService: (state, action: PayloadAction<ExtraService>) => {
            const extraService = action.payload;
            if(state.extraServices.indexOf(extraService) === -1) {
                state.extraServices = [...state.extraServices, extraService];
            }
        },
        removeExtraService: (state, action: PayloadAction<ExtraService>) => {
            const extraService = action.payload;
            const index = state.extraServices.map(extraService => extraService.id).indexOf(extraService.id);
            if(index !== -1) {
                state.extraServices = [
                    ...state.extraServices.slice(0, index),
                    ...state.extraServices.slice(index + 1)
                ];
            }
        },
        previousMonth: (state) => {
            state.date = undefined;
            state.slot = undefined;
            state.yearMonth = moment(state.yearMonth, YEAR_MONTH_FORMAT).subtract(1, 'month').format(YEAR_MONTH_FORMAT);
        },
        nextMonth: (state) => {
            state.date = undefined;
            state.slot = undefined;
            state.yearMonth = moment(state.yearMonth, YEAR_MONTH_FORMAT).add(1, 'month').format(YEAR_MONTH_FORMAT);
        },
        setDate: (state, action: PayloadAction<string>) => {
            state.date = action.payload;
            state.slot = undefined;
        },
        setSlot: (state, action: PayloadAction<Slot>) => {
            state.slot = action.payload;
        },
    },
});

export const {completeCustomerInfoStep, completeVisitingAddressInfoStep, completeSchemeStep, completeExtraServicesStep, completeCalendarStep, completeOverviewStep, addYearMonthWithoutSuggestions} = customerBookingWizardSlice.actions;
export const {setCustomerData, setFirstName, setLastName, setPhoneNumber, previousMonth, nextMonth, setScheme, setVisitingAddress, finalizeSchemeSelection, addExtraService, removeExtraService, setDate, setSlot } = customerBookingWizardSlice.actions;

export const selectUserInfoStep = (state: RootState) => state.customerBookingWizard.wizard.userInfo;
export const selectVisitingAddressInfoStep = (state: RootState) => state.customerBookingWizard.wizard.visitingAddressInfo;
export const selectSchemeStep = (state: RootState) => state.customerBookingWizard.wizard.scheme;
export const selectExtraServicesStep = (state: RootState) => state.customerBookingWizard.wizard.extraServices;
export const selectCalendarStep = (state: RootState) => state.customerBookingWizard.wizard.calendar;
export const selectOverviewStep = (state: RootState) => state.customerBookingWizard.wizard.overview;

export const selectCustomerId = (state: RootState) => state.customerBookingWizard.customer?.id;
export const selectEmail = (state: RootState) => state.customerBookingWizard.customer?.email;
export const selectFirstName = (state: RootState) => state.customerBookingWizard.customer?.firstName;
export const selectLastName = (state: RootState) => state.customerBookingWizard.customer?.lastName;
export const selectPhoneNumber = (state: RootState) => state.customerBookingWizard.customer?.phoneNumber;

export const selectVisitingAddress = (state: RootState) => state.customerBookingWizard.visitingAddress;
export const selectScheme = (state: RootState) => state.customerBookingWizard.scheme;
export const selectExtraServices = (state: RootState) => state.customerBookingWizard.extraServices;

// TODO: moment global instellen wil niet lukken
export const selectYearMonth = (state: RootState) => moment(state.customerBookingWizard.yearMonth, YEAR_MONTH_FORMAT).locale('nl-be');
export const selectReadableYearMonth = (state: RootState) => state.customerBookingWizard.yearMonth;

export const selectDate = (state: RootState) => state.customerBookingWizard.date ? moment(state.customerBookingWizard.date) : undefined;
export const selectReadableDate = (state: RootState) => state.customerBookingWizard.date ? state.customerBookingWizard.date : undefined;

export const selectSlot = (state: RootState) => state.customerBookingWizard.slot;

export default customerBookingWizardSlice.reducer;