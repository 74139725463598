import React, {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import SchemeComponent from '../../booking/scheme/SchemeComponent';
import SubTitle from '../../helpers/text/Subtitle';
import {selectScheme, setScheme} from '../../../store/reducers/SecretariatBookingSlice';
import {Scheme} from '../../../model/Scheme';
import StepCompleted from '../StepCompleted';
import {useQuery} from '@tanstack/react-query';
import {fetchRootLinks} from '../../../api/Root';
import {schemeQueries} from '../../../api/Schemes';

function SchemeSelector(): ReactElement {
    const dispatch = useAppDispatch();

    const rootLinks = fetchRootLinks();
    const { data: schemes} = useQuery(schemeQueries.findAll(rootLinks));

    const onSelectScheme: (scheme: Scheme) => void = (scheme: Scheme) => {
        dispatch(setScheme(scheme));
    }

    const selectedScheme = useAppSelector(selectScheme);

    // TODO: wrapper maken voor secretariat sectie met loader
    return (
        <div>
            <SubTitle>Selecteer dienst <StepCompleted complete={!!selectedScheme} /></SubTitle>
            <div className='w-full flex flex-col items-center justify-center'>
                {schemes?.map(scheme => <SchemeComponent key={scheme.id} scheme={scheme} checked={scheme === selectedScheme} onSelectScheme={() => onSelectScheme(scheme)} />)}
            </div>
        </div>
    );
}

export default SchemeSelector;