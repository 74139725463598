import React, {ReactElement} from 'react';
import {NavItemDataType} from '../../NavItemDataType';
import {c} from '../../../../utils/CssFunctions';
import {Link} from 'react-router-dom';
import {visibleItems} from '../../HeaderHelper';

type DropdownPropType = {
    dropdown: boolean;
    items: Array<NavItemDataType>;
};

type DropdownItemPropType = {
    item: NavItemDataType;
};

function Dropdown(props: DropdownPropType): ReactElement {
    return (
        <ul className={c('absolute right-5 top-full list-none bg-white-darkest border-2 border-riptide-darker shadow-md rounded', props.dropdown ? 'block' : 'hidden')}>
            {visibleItems(props.items!).map((item, index) => <DropdownItem key={index} item={item} />)}
        </ul>
    )
}

function DropdownItem(props: DropdownItemPropType): ReactElement {
    return (
        <li className='flex border-b last:border-b-0 border-riptide-darker'>
            <DropdownActionItem item={props.item} />
        </li>
    )
}

function DropdownActionItem(props: DropdownItemPropType): ReactElement {
    if(props.item.onClick) {
        return (
            <span onClick={() => props.item.onClick!()} className={c('w-full cursor-pointer text-md px-4 py-1 decoration-[3px] transition-all duration-300 text-riptide-darker hover:text-riptide hover:bg-white-darker')}>
                {props.item.title}
            </span>
        )
    }

    return (
        <Link to={props.item.url!} className={c('w-full text-md px-4 py-1 decoration-[3px] transition-all duration-300 text-riptide-darker hover:text-riptide hover:bg-white-darker')}>
            {props.item.title}
        </Link>
    )
}

export default Dropdown;

