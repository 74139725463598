import React, {MutableRefObject, ReactElement, useEffect, useRef, useState} from 'react';
import {NavItemDataType} from '../../NavItemDataType';
import {Link, useLocation} from 'react-router-dom';
import {c} from '../../../../utils/CssFunctions';
import {IconChevronDown} from '@tabler/icons-react';
import Dropdown from './Dropdown';

type NavItemPropType = {
    item: NavItemDataType;
    hideOverlayFunction: () => void;
}

function NavItem(props: NavItemPropType): ReactElement {
    if(props.item.subItems) {
        return (
            <li className="flex flex-col">
                <NavDropdownItem item={props.item} hideOverlayFunction={props.hideOverlayFunction} />
            </li>
        )
    }

    return (
        <li className="flex">
            <NavActionItem item={props.item} hideOverlayFunction={props.hideOverlayFunction} />
        </li>
    );
}

function NavActionItem(props: NavItemPropType): ReactElement {
    const location = useLocation();
    const isSelected = location.pathname === props.item.url;

    const onClick: () => void = () => {
        props.hideOverlayFunction();
        props.item.onClick && props.item.onClick();
    }

    if(props.item.onClick) {
        return (
            <span className={c('w-full cursor-pointer text-lg px-8 py-3 decoration-[3px] transition-all duration-300 border-b border-riptide', isSelected ? 'text-riptide' : 'text-white hover:text-riptide')} onClick={() => onClick()}>
                {props.item.title}
            </span>
        );
    }

    return (
        <Link to={props.item.url!}
              className={c('w-full text-lg px-8 py-3 decoration-[3px] transition-all duration-300 border-b border-riptide', isSelected ? 'text-riptide' : 'text-white hover:text-riptide')} onClick={() => onClick()}>
            {props.item.title}
        </Link>
    )
}

function NavDropdownItem(props: NavItemPropType): ReactElement {
    const [dropdown, setDropdown] = useState(false);
    const ref: MutableRefObject<any> = useRef();

    useEffect(() => {
        const handler = (event: MouseEvent | TouchEvent) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setTimeout(() => {
                    setDropdown(false);
                }, 300)
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    return (
        <>
            <span ref={ref} onClick={() => setDropdown(!dropdown)} className={c('w-full flex flex-row items-center cursor-pointer text-lg px-8 py-3 decoration-[3px] transition-all duration-300 border-b border-riptide', dropdown ? 'text-riptide' : 'text-white hover:text-riptide')}>
                {props.item.title}
                <IconChevronDown size={18} className={c('transition-transform', dropdown ? '-rotate-180' : '')}/>
            </span>
            <Dropdown dropdown={dropdown} items={props.item.subItems!} hideOverlayFunction={props.hideOverlayFunction} />
        </>
    )
}

export default NavItem;