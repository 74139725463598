import React, {ReactElement} from 'react';
import AdminPageWrapper from '../../../components/helpers/wrappers/AdminPageWrapper';
import {hasLink} from '../../../utils/HateoasFunctions';
import {InternalLink} from '../../../components/helpers/text/InternalLink';
import {fetchRootLinks} from '../../../api/Root';
import {useQuery} from '@tanstack/react-query';
import {technicianQueries} from '../../../api/Technicians';

function TechnicianOverview(): ReactElement {
    const rootLinks = fetchRootLinks();
    const { data: technicianOverview, isLoading: isLoadingTechnicians} = useQuery(technicianQueries.findAll(rootLinks, {sort: 'userData.email'}));

    const technicians = technicianOverview?._embedded.technicians || [];

    // TODO: table loader
    if(isLoadingTechnicians) {
        return (
            <h3>Loading...</h3>
        );
    }

    return (
        <AdminPageWrapper title='Techniekers'>
            <table className='border border-collapse'>
                <thead>
                <tr>
                    <th className='border'>Email</th>
                    <th className='border'>Voornaam</th>
                    <th className='border'>Achternaam</th>
                    <th className='border'>Details</th>
                </tr>
                </thead>
                <tbody>
                {technicians.map(technician => {
                    return (
                        <tr key={technician.id}>
                            <td className='border'>{technician.email}</td>
                            <td className='border'>{technician.firstName}</td>
                            <td className='border'>{technician.lastName}</td>
                            <td className='border'>
                                {hasLink(technician, 'self') &&
                                  <InternalLink to={`${technician.id}`}>details</InternalLink>}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </AdminPageWrapper>
    );
}

export default TechnicianOverview;